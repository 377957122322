import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../modal/Modal";

import LOGO from "../../assets/icons/LOGO.svg";

import "./Footer.scss";
import TermsOfUse from "../policy/TermsOfUse";
import CollectionOfPersonalInfo from "../policy/CollectionOfPersonalInfo";

const Footer = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(0);
  const userId = useSelector((state) => state.user.userInfo?.userId);

  const inquire = () => {
    if (userId) {
      dispatch(push("/email-inquire"));
    } else {
      setModalOpen(modalData.length);
    }
  };

  const modalData = [
    {
      header: "이용약관",
      size: "md",
      sort: "center",
      content: <TermsOfUse />,
      ico: false,
      leftBtn: false,
      rightBtn: false,
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
    },

    {
      header: "개인정보처리방침",
      size: "md",
      sort: "center",
      content: <CollectionOfPersonalInfo />,
      ico: false,
      leftBtn: false,
      rightBtn: false,
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
    },

    {
      header: "로그인 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          로그인이 필요한 서비스입니다.
          <br />
          로그인 페이지로 이동하시겠습니까?
        </p>
      ),
      btnType: "point",
      ico: true,
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(push("/sign-in"));
        setModalOpen(0);
      },
    },
  ];

  const linkArr = [
    {
      title: "이용약관",
      path: "https://olea-market-static.s3.ap-northeast-2.amazonaws.com/oleamarket-terms-of-service.pdf",
    },
    {
      title: "개인정보처리방침",
      path: "https://olea-market-static.s3.ap-northeast-2.amazonaws.com/oleamarket-privacy-policy.pdf",
    },
  ];

  const companyInfo = [
    { id: 1, content: "회사명 : 이지시큐브이" },
    { id: 2, content: "사업자등록번호 : 552-81-02959" },
    { id: 3, content: "회사주소 : 경기도 성남시 위례광장로 19 701호" },
    {
      id: 4,
      content: "전화번호 : 02-6953-1535",
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="footer_wrapper">
        <div className="footer_inner_wrapper">
          <div className="footer_inner_wrapper_left">
            <img src={LOGO} alt="OLEA MARKET LOGO" />
            {companyInfo.map((data) => (
              <p key={data.id} className="graytone_text">
                {data.content}
              </p>
            ))}
          </div>
          <div className="footer_inner_wrapper_right">
            <div className="footer_inner_wrapper_right_content">
              {linkArr.map((cur, idx) => {
                return (
                  <p
                    key={idx}
                    className="clickable_text"
                    onClick={() => {
                      if (cur.title === "이용약관")
                        return window.open(cur.path, "__blank");

                      setModalOpen(idx + 1);
                    }}
                  >
                    {cur.title}
                  </p>
                );
              })}
              <p
                className="clickable_text"
                onClick={inquire}
                type="text_sm_normal"
                color="gray400"
              >
                이메일 문의
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
